document.addEventListener('DOMContentLoaded', () => {
    /* mobile menu function */
    $(".hamburger").click(function () {
        $(".main-menu").slideToggle("fast");
        if ($('.hamburger').hasClass("is-active")) {
            $('.hamburger').removeClass('is-active');
            $('.main-menu').removeClass('active');
            $('body').removeClass('overflow');
        } else {
            $('.hamburger').addClass('is-active');
            $('.main-menu').addClass('active');
            $('body').addClass('overflow');
        }
    });
    /* mobile subitem menu */
    $('.submenu__item.submenu-item').on('click', '.submenu-sub-link', function (){
       if($(this).hasClass('active')){
           $(this).removeClass('active');
           $(this).siblings('.submenu-list').slideUp('fast');
       }
       else{
           $(this).addClass('active');
           $(this).siblings('.submenu-list').slideDown('fast');
       }
    });

    /* on scroll function */
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll > 800) {
            $(".top-phone").fadeIn("fast");
        }
        else{
            $(".top-phone").fadeOut("fast");
        }
    });

    /* modals */
    $('a[href="#backcall_modal"]').on('click', function (){
        if($(this).attr('data-service') && $(this).attr('data-service') != ''){
            $('#backcall_modal input[name="service"]').val($(this).attr('data-service'));
        }
        else {
            $('#backcall_modal input[name="service"]').val('');
        }
    });
    $('.modal-win').magnificPopup({
        type: 'inline',
        fixedContentPos: true,
        fixedBgPos: true,
        closeBtnInside: true,
        midClick: true,
        removalDelay: 300,
        tClose: 'Закрыть',
        mainClass: 'my-mfp-zoom-in',
        image: {
            tError: 'Невозможно загрузить :('
        },
        ajax: {
            tError: 'Невозможно загрузить :('
        }
    });
    /* form validate */
    /* mask for phone */
    $('input.phone-input').mask('+7 ( 999 ) 999-99-99');
    /* mask for name (without numbers) */
    $('input.name-input').keypress(function (key) {
        if (key.charCode < 48 || key.charCode > 57) return true;
        return false;
    });
});



